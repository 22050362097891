.certificationPageContainer{
  position: relative;
  width: 100%;
  min-height: 750px;
  text-align: left;

  background-size: 100% 750px;
  background-position: top;
  background-repeat: no-repeat;

  .title{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    padding-top: 87px;
    padding-left: 16px;
  }

  .subtitle{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding-top: 7px;
    padding-left: 16px;
    padding-bottom: 70px;
  }

  .confirmButton{
    width: 343px;
    height: 44px;
    border-radius: 40px;
    margin: 0 auto;
    color: #FFFFFF;
  }

  .inputContainer{
    width: 90%;
    margin: 0 auto;
    padding-top: 150px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;

    color: rgba(255, 255, 255, 0.8);

    .codeContainer{
      position: relative;
      .codeButton{
        position: absolute;
        right: 0;
        bottom: 12px;
      }
    }

    input{
      margin-top: 10px;
      width: calc(100vw - 32px);
      height: 40px;
      background: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      color: white;
      padding-left: 0;
      font-size: 16px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input:focus{
      outline:none;
    }

    ::placeholder{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-transform: lowercase;
      color: rgba(255, 255, 255, 0.5);
      margin-left: 10px;
    }

    .warningText{
      color: #FF3F3F;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      margin-top: 58px;
      margin-bottom: 23px;
    }
  }
  .confirmButton{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.certificationSuccessContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 750px;
  width: 100%;

  background-size: 100% 750px;
  background-position: top;
  background-repeat: no-repeat;

  color: #FFFFFF;

  .confirmButton{
    border-radius: 40px;
    width: 335px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .nameContainer{
    margin-top: 163px;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 31px;
  }

  .idContainer{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 18px;
    margin-top: 11px;
  }

  .numberContainer{
    width: 95%;
    height: 50px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    display: flex;
    align-items: center;
   justify-content: center;

    .number{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .currentAccountTitle{
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}