.poster-container{
  position: relative;
  z-index: 4;
  width: 100vw;
  min-height: 100vh;

  .reminder{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 64px;
    bottom: 34px;
    color: white;
    font-size: 14px;

    img{
      margin-top: 10px;
      width:19px;
      height: 33px;
    }
  }

  .download-icon {
    position: absolute;
    top:29px;
    right:18px;
    height: 24px;
    width: 24px;
    z-index: 99;
  }
}

.stageDiv{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 780px;
  overflow-y: hidden;

  background-size: 100% 779px;
  background-repeat: no-repeat;
  background-position: top;

  .poster-page-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 42px;
  }

  .poster-page-subtitle-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    -webkit-transform: translate(-50%, -30%);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #5F9CDA;
  }

  .poster-page-frame-container{
    height: 581px;
    width: 291px;
    position: relative;
    margin-top: 5px;

    .poster-page-frame-container-inline-frame{
      height: 328px;
      width: 245px;
      left: 50%;
      margin-left: -122px;

      position: absolute;
      top: 56px;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  .nft-poster-title {
    max-width: 265px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 1px;
    color: #101010;
    text-shadow: 0 0 6px rgba(255, 231, 174, 0.4);
    margin: 30px auto 13px auto;
  }

  .nft-poster-title-dark-theme {
    color: #FFFFFF;
    text-shadow: 0 0 6px rgba(255, 231, 174, 0.4);
  }

  .nft-poster-subtitle {
    width: 132px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #101010;
    background: linear-gradient(90deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.2) 45.57%, rgba(16, 16, 16, 0) 87.5%);
    margin: 0 auto;
  }

  .nft-poster-subtitle-dark-theme {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 41.68%, rgba(255, 255, 255, 0) 87.5%);
    color: #FFFFFF;
  }

  .nft-poster-image-container {
    width: 295px;
    height: 295px;
    border-radius: 12px;
    margin: 30px auto 0 auto;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nft-poster-image-container img{
    width: 100%;
  }

  .poster-bottom-info-container {
    width: 266px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto 0 auto;

    position: absolute;
    bottom: 140px;

    .bottom-info-title {
      width: 200px;
      font-family: 'FZLanTingHeiS-B-GB';
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      color: #5F9CDA;
      margin: 0 auto 0 auto;
    }

    .bottom-info-title-dark-theme {
      color: #FFFFFF;
    }

    .bottom-info-sub-container {
      width: 90%;
      height: 25px;
      display: flex;
      flex-direction: row;
      align-items:center;
      justify-content: space-between;
      margin: 0 auto;

      .sub-container-title {
        font-family: 'FZLanTingHeiS-B-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #5F9CDA;

        width: 60px;
        text-align: left;
        white-space:nowrap;
      }

      .sub-container-title-dark-theme {
        color: #959595;
      }

      .sub-container-content {
        font-family: 'FZLanTingHeiS-B-GB';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #5F9CDA;
        width: 200px;
        text-align: right;
        white-space:nowrap;
      }

      .sub-container-content-dark-theme {
        color: #FFFFFF;
      }
    }
  }

  .poster-bottom-info-container-dark-theme {
    background: url("./assets/border-background.png") no-repeat center;
    background-size: 100% 100%;
  }

  .poster-bottom-qrcode-container {
    position: relative;
    width: 290px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .qrcode-container {
      display: flex;
      flex-direction: row;

      .bottom-left-qrcode {
        width:60px;
        height:60px;
        margin-left: 10px;
      }

      .qrcode-info {
        width: 140px;
        margin-left:8px;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        line-height: 20px;

        margin-top:21px;

        font-weight: 400;
        font-size: 12px;
        color: #5584B1;
      }

      .qrcode-info-dark-theme {
        color: #FFFFFF;
      }
    }

    .logo-group {
      background: url("./assets/poster-logo.png") no-repeat center;
      background-size: 100% 23px;
      width: 93px;
      margin-top:32.5px;
    }
  }
}

.stageDiv-dark-theme {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 100%);
}