.progress-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:#1d1d1d;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: 100% 100vh;

  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-icon {
      background: url("./assets/loading.gif") no-repeat center;
      background-size: 100% 100%;
      width: 120px;
      height: 20px;
      margin-bottom: 17px;
    }

    .loading-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #5F9CDA;
    }

    .bottom-text {
      position: absolute;
      bottom: 20px;
      width: 96%;
      font-weight: 400;
      font-size: 9px;
      line-height: 16px;
      text-align: center;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}