.ok-button-container{
  width: 192px;
  position: relative;

  .ok-button-content-text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    -webkit-transform: translate(-50%, -60%);

    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #5F9CDA;
    font-family: 'FZLanTingHeiS-B-GB';
  }
}