.desc-container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .desc-content{
    max-width: 240px;
    height: 41px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    white-space:pre-wrap;
  }

  .desc-content-dark-theme {
    color: rgba(255, 255, 255, 0.7);
  }
}