#about-container{
  position: relative;
  min-height: 750px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-size: 100% 750px;
  background-repeat: no-repeat;

  #about-content{
    width: 85%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    z-index: 20;
    text-align: justify;

    height: 450px;
    margin-top: 124px;
    overflow-y: scroll;

    padding-right: 10px;
    padding-left: 10px;

    #about-title{
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      text-align: justify;
    }

    .lineBetween{
      margin: 0px 0px 8px 0px;
    }

    .lineBetween:last-child {
      margin-bottom: 0px;
    }
  }

  .about-page-ornament1 {
    position: absolute;
    top:33px;
    right:0;
    height: 349px;
    width:258px;
    z-index:10
  }

  .about-page-ornament2 {
    position: absolute;
    top:-82px;
    right:0;
    height: 262px;
    width:180px;
    z-index: 10;
  }
}