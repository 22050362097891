.claim-page {
  position: relative;
  width: 100vw;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  align-items:center;

  background-size: 100% 750px;
  background-position: top;
  background-repeat: no-repeat;

  //.card-container {
  //  position: relative;
  //  background: url("./assets/light-claim-container.png") no-repeat center;
  //  background-size: 100% 100%;
  //  width: 303px;
  //  height: 303px;
  //  margin-top:110px;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //
  //  .question-mark {
  //    position: relative;
  //    width: 190px;
  //    height: 190px;
  //  }
  //}
  //
  //.card-container-dark-theme {
  //  background: url("./assets/dark-claim-container.png") no-repeat center;
  //  background-size: 100% 100%;
  //}

  .input-container {
    position: relative;
    width: 184px;
    height: 36px;

    box-sizing: border-box;
    display:flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 28px;

    margin-top:33px;
    margin-bottom: 26px;

    input{
      color: #5F9CDA;
    }

    input::-webkit-input-placeholder{
      color: rgba(95, 156, 218, 0.8);
    }

    input::placeholder{
      color: rgba(95, 156, 218, 0.8);
    }

    .input-code {
      border:0;
      outline: none;
      background: transparent;
      padding: 0 10px 0 10px;
      width: 100%;
      height: 14px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }

    .input-code-dark-theme {
      color: #ffffff
    }
  }

  .claim-button {
    position: relative;
    width: 228px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FAFAFA;
    margin-top: 30px;
    margin-bottom: 95px;
  }
}

.claim-page-dark-theme {
  background: #1D1D1D;
}