.arrow-icon-container {
  position: absolute;
  top:18px;
  left:16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content:center;
  align-items:center;
  z-index: 99;
}

.arrow-icon-dark {
  background: url("./assets/icon-container-dark.png") no-repeat center;
  background-size: 100% 100%;
}

.arrow-icon-light {
  background: url("./assets/icon-container-light.png") no-repeat center;
  background-size: 100% 100%;
}