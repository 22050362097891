.policy-page {
  position: relative;
  min-height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content:center;
    width: 100%;
    margin-top: 27px;
    padding-bottom: 18px;
    margin-bottom: 16px;
  }

  .light-theme-border {
    border-bottom: 1px solid #F4F4F4;
  }

  .dark-theme-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .back-icon {
    position: absolute;
    left: 18px;
    width:13px;
    height:13px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .content {
    white-space: pre-wrap;
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    padding-bottom: 20px;
    opacity: 0.8;
  }
}

.policy-dark-theme {
  background-color:#1d1d1d;
  color:#ffffff
}

.policy-light-theme {
  color: #ffffff;
  color: #101010
}