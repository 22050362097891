.authModalContainer{
  position: absolute;
  top: 0;

  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  .modal{
    width: 343px;
    height: 222px;
    background: #FFFFFF;
    border-radius: 6px;
    position: relative;
    margin-top: -35px;

    .icon{
      width: 20px;
      position: absolute;
      bottom: -71px;
      left: 162px;
    }

    .title{
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .line{
      width: 100%;
      height: 1px;
      background-color: #F1F1F1;
      margin-bottom: 23px;
    }

    .contentText{
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.6);
    }

    .buttonContainer{
      box-shadow: 0px 7px 8px rgba(132, 121, 225, 0.1);
      border-radius: 31px;
      width: 228px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 25px auto 0 auto;
    }

    .connectLine{
      position: absolute;
      bottom: -51px;
      left: 171px;
      height:50px;
      width: 1px;
      background-color: #FFFFFF;
      opacity: 0.3;
    }
  }
}