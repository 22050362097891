.detail-page {
  position: relative;
  width: 100vw;
  height: 864px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-size: 100% 864px;
  background-position: top;
  background-repeat: no-repeat;

  overflow-y: hidden;

  .detail-info-content-footer{
    position:fixed;
    bottom: 0;
    left :0;
    right: 0;
    height: 76px;
    background: rgba(209, 231, 243, 0.5);
    backdrop-filter: blur(5px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .details-page-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 17px;
  }

  .details-page-subtitle-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    -webkit-transform: translate(-50%, -30%);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #5F9CDA;
  }

  .details-page-frame-container{
    width: 298px;
    margin-bottom: 36px;
    margin-top: 6px;
    position: relative;

    .details-page-frame-container-inline-img-container{
      height: 336px;
      width: 252px;

      position: absolute;
      left: 50%;
      margin-left: -125px;
      top: 37px;

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;
    }

    .details-page-frame-container-info-container{
      width: 267px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #5F9CDA;

      position: absolute;
      left: 50%;
      top: 403px;
      margin-left: -133px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .details-page-frame-container-info-container-line-container{
        width: 95%;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: #5F9CDA;

        margin-bottom: 12px;
      }
    }
  }

  .detail-nft-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #101010;
    margin-top:25px;
  }

  .detail-nft-title-dark-theme {
    color: #FFFFFF
  }

  .detail-nft-id {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #101010;
  }

  .detail-nft-id-dark-theme {
    color: rgba(255, 255, 255, 0.8);
  }

  .top-nft-info-container {
    position: relative;
    width: 335px;
    height: 378px;
    background: #FFFFFF;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin-top:18px;
    display:flex;
    flex-direction: column;
    align-items: center;

    .inside-nft-img {
      width: 309px;
      height: 309px;
      border-radius: 12px 12px 0 0;
      margin-top: 12px;
    }

    .inside-nft-video{
      width: 309px;
      height: 309px;
      border-radius: 12px 12px 0 0;
      margin-top: 12px;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .nft-card-bottom-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 309px;
      margin-top:11px;

      .detail-nft-avatar {
        width:32px;
        height:32px;
        margin-right:10px;
        border:1px solid white;
        border-radius: 50%;
      }

      .detail-nft-author-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0A0E2E;
      }

      .detail-nft-author-name-dark-theme {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .top-nft-info-container-dark-theme {
    background: #3F3F3F;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.04);
  }

  .bottom-nft-info-container {
    position: relative;
    width: 343px;
    height: 225px;
    background: #FFFFFF;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin-top: 30px;
    display:flex;
    flex-direction: column;
    align-items: center;

    .bottom-info-title {
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #101010;
      width: 90%;
      height:60px;
      display:flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid #EBEBEB;
      margin-bottom: 5px;
    }

    .bottom-info-title-dark-theme {
      color: #FFFFFF;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    }

    .bottom-detail-into-container {
      position: relative;
      width: 90%;
      height:29px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .detail-info-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: rgba(16, 16, 16, 0.8);
      }

      .detail-info-title-dark-theme {
        color: rgba(255, 255, 255, 0.8);
      }

      .detail-info-content {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba(16, 16, 16, 0.6);
      }

      .address-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .address-copy-button {
        background: url("./assets/copy-icon.png") no-repeat center;
        background-size: 100% 100%;
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }

      .detail-info-content-dark-theme {
        color: rgba(255, 255, 255, 0.6);
      }
    }

  }

  .bottom-nft-info-container-dark-theme{
    background: #3F3F3F;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.04);
  }

  .detail-page-footer-warning {
    position: relative;
    width: 350px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom:108px;
  }

  .detail-page-footer-warning-dark-theme {
    color: #FFFFFF;
    opacity: 0.5;
  }

  .go-to-poster-button {
    width: 335px;
    height: 50px;
    background: linear-gradient(180deg, #2A7DF9 0%, rgba(42, 125, 249, 0.7) 100%);
    border-radius: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 22px;
  }
}

.detail-page-dark-theme {
  background: #1D1D1D;
}