.list-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;

  .content-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 28px;
    height: 78vh;
    align-content: flex-start;
    width: 360px;
    margin-bottom: 105px;

    .detail-container {
      position: relative;
      width: 148px;
      margin-left: 17px;
      margin-right: 15px;
      margin-bottom: 13px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;



      .list-nft-author {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #555555;
        margin-top: 7px;
        text-align: start;
      }

      .list-nft-author-dark-theme {
        color: rgba(255, 255, 255, 0.6);
      }

      .list-nft-name {
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #23262F;
        margin-top: 7px;
        text-align: start;
        width: 100%
      }

      .list-nft-name-dark-theme {
        color: rgba(255, 255, 255, 0.8);
      }

      .detail-container-inline-container {
        position: absolute;
        top: 20px;
        left: 14px;
        height: 170px;
        width: 125px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        .nft-image {
         height: 100%;
        }
      }
    }

    .plus-button {
      width: 148px;
      height: 148px;
      background: #FFFFFF;
      border: 1px solid rgba(232, 232, 232, 0.32);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(232, 232, 232, 0.25);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      .plus-image {
        width: 39.5px;
        height: 39.5px
      }
    }

    .plus-button-dark-theme {
      background: #3F3F3F;
      border: 0;
      box-shadow: 0px 0px 0px #3F3F3F;
    }
  }
}

.list-page-dark-theme {
  background: #1d1d1d
}