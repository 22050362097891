.congrats-page {
  position: relative;
  min-height: 750px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-size: 100% 750px;
  background-position: top;
  background-repeat: no-repeat;

  .congrats-page-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 17px;
  }

  .congrats-page-subtitle-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    -webkit-transform: translate(-50%, -30%);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #5F9CDA;
  }

  .congrats-page-inline-img-container{
    height: 333px;
    width: 250px;

    position: absolute;
    top: 45px;
    left: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .congrats-page-desc-container{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 260px;

    margin-top: 23px;
    margin-bottom: 13px;
  }

  .nft-background-image {
    position: absolute;
    top:0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    filter: blur(20px);
  }

  .nft-container {
    position: relative;
    height:480px;
    width: 100%;
    background: linear-gradient(0deg, #ffffff 0%, rgba(250, 250, 250, 0.76) 22%, rgba(248, 248, 249, 0.69) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    margin-bottom: 5px;

    .nft-background-light {
      position:absolute;
      width: 100vw;
      top:46px;
    }

    .nft-title {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 1px;
      color: rgba(16, 16, 16, 0.8);
      text-shadow: 0px 0px 6px rgba(255, 231, 174, 0.4);
      margin-top:40px;
    }

    .nft-title-dark-theme {
      color: #FFFFFF
    }

    .nft-id {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      color: rgba(16, 16, 16, 0.6);
      margin-top:7px
    }

    .nft-id-dark-theme {
      color: rgba(228, 228, 228, 0.8);
    }

    .nft-image {
      position: relative;
      width:300px;
      height:300px;
      border-radius: 12px;
      margin-top: 26px;
    }
  }

  .nft-container-dark-theme {
    background: linear-gradient(0deg, #1D1D1D 0%, rgba(0, 0, 0, 0.76) 22%, rgba(0, 0, 0, 0.69) 100%);
  }

  .confirm-button {
    position: relative;
    width: 228px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 31px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FAFAFA;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

.congrats-page-dark-theme {
  background: #1D1D1D;
}